.app-header {
  .navbar {
    min-height: 40px;
    padding: 0 25px;

    &.navbar-dark {
      background-color: #93c54b;
    }

    .navbar-brand {
      height: 40px;
      padding-top: 5px;

      a {
        color: #fff;
      }
    }
    .navbar-nav {
      font-size: 11px;
      line-height: 22px;
      font-weight: 500;
      text-transform: uppercase;

      .nav-link {
        padding: 10px 15px;
        color: #fff;
      }

      .dropdown-menu {
        left: auto;
        right: 0;
        font-size: 11px;
        line-height: 22px;
        font-weight: 500;
        text-transform: uppercase;
        color: #98978b;
      }
    }

    .navbar-toggler {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
