.grid-component-panel {
  .panel-body {
    padding: 5px;
  }
}

.react-draggable {
  z-index: 5;
  cursor: default;

  .move-handler, .remove-handler, .resize-handler, .edit-handler {
    opacity: 0.2;
  }
  &:hover {
    .move-handler, .remove-handler, .resize-handler, .edit-handler {
      opacity: 0.4;
    }
  }
  .move-me {
    cursor: move;
  }
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: nesw-resize;
}

.remove-handler, .edit-handler {
  cursor: pointer;
}
