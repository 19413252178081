body {
  //background: darkcyan;
}

.page-title {
  margin-top: 0px;
}

.pull-right {
  float: right !important;
}

input[type='checkbox'],
input[type='radio'] {
  height: 1rem;
}

.form-text.text-muted {
  color: red !important;
}

.navbar {
  margin-bottom: 20px;
}

.play-icons-left, .play-icons-right {
  color: #aaa;
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px
}
