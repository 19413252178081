.filter-box {
  .container {
    width: 100%
  }

  .filter-button {
    .btn {
      padding: 2px;
      height: 50px;
      overflow: hidden;
      text-transform: none;
      white-space: normal;
      line-height: 110%;
      color: #3e3f3a;
      background-color: #f8f5f0;
      border: 1px solid #dfd7ca;
    }
  }
}
.hierarchy-panel {
  &.modal-dialog {
    width: 400px;
  }
}
.range-panel {
  &.modal-dialog {
    width: 90%;

    .modal-body {
      padding: 30px;
      height: 120px;
    }
  }
}
