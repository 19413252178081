.hierarchy-selector {
  tr {
    border-bottom: 1px solid #ECE9E9;
    td {
      padding-top: 3px;
      border-bottom: 1px solid #ECE9E9;

      div.checkbox, div.radio {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
}
